






export const DAYOFWEEK = ['Sunday', 'Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
export const DAYOFWEEK_EN = ['Sunday', 'Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
export const DAYOFWEEK_JP = ['日曜日', '月曜日','火曜日','水曜日','木曜日','金曜日','土曜日']

export const MONTH_EN = [
    'January','February','March','April','May','June',
    'July','August','September','October','November','December'
]
export const MONTH_EN_SHORT = [
    'Jan','Feb','Mar','Apr','May','Jun',
    'Jul','Aug','Sept','Oct','Nov','Dec'
]

export const DAYOFWEEKLIST = ['Sun','Mon','Tues','Wed','Thurs','Fri','Sat']


export const VARIANTS = ['primary','secondary','warning']
export const SIZE = ['sm','md','lg']



export const PREFECTURES = [
    'Aichi', 'Akita', 'Aomori', 'Chiba', 'Ehime', 
    'Fukui', 'Fukuoka', 'Fukushima', 'Gifu', 'Gunma', 
    'Hiroshima', 'Hokkaido', 'Hyogo', 'Ibaraki', 'Ishikawa', 
    'Iwate', 'Kagawa', 'Kagoshima', 'Kanagawa', 'Kochi', 
    'Kumamoto', 'Kyoto', 'Mie', 'Miyagi', 'Miyazaki', 
    'Nagano', 'Nagasaki', 'Nara', 'Niigata', 'Oita', 
    'Okayama', 'Okinawa', 'Osaka', 'Saga', 'Saitama', 
    'Shiga', 'Shimane', 'Shizuoka', 'Tochigi', 'Tokushima', 
    'Tokyo', 'Tottori', 'Toyama', 'Wakayama', 'Yamagata', 
    'Yamaguchi', 'Yamanashi']

export const PREFECTURES_JP = [
    '北海道','青森県','岩手県','宮城県','秋田県',
    '山形県','福島県','茨城県','栃木県','群馬県',
    '埼玉県','千葉県','東京都','神奈川県','富山県',
    '石川県','福井県','新潟県','山梨県','長野県',
    '岐阜県','静岡県','愛知県','三重県','滋賀県',
    '京都府','大阪府','兵庫県','奈良県','和歌山県',
    '鳥取県','島根県','岡山県','広島県','山口県',
    '徳島県','香川県','愛媛県','高知県','福岡県',
    '佐賀県','長崎県','熊本県','大分県','宮崎県',
    '鹿児島県','沖縄県'
]


export const GENDER_CHOICES = [
    ('男性', "Male"),
    ('女性', "Female"),
]


export const PREFECTURE_CHOICES = [
    ('北海道','Hokkaido'),
    ('青森県','Aomori'),
    ('岩手県','Iwate'),
    ('宮城県','Miyagi'),
    ('秋田県','Akita'),
    ('山形県','Yamagata'),
    ('福島県','Fukushima'),
    ('茨城県','Ibaraki'),
    ('栃木県','Tochigi'),
    ('群馬県','Gunma'),
    ('埼玉県','Saitama'),
    ('千葉県','Chiba'),
    ('東京都','Tokyo'),
    ('神奈川県','Kanagawa'),
    ('富山県','Toyama'),
    ('石川県','Ishikawa'),
    ('福井県','Fukui'),
    ('新潟県','Niigata'),
    ('山梨県','Yamanashi'),
    ('長野県','Nagano'),
    ('岐阜県','Gifu'),
    ('静岡県','Shizuoka'),
    ('愛知県','Aichi'),
    ('三重県','Mie'),
    ('滋賀県','Shiga'),
    ('京都府','Kyoto'),
    ('大阪府','Osaka'),
    ('兵庫県','Hyogo'),
    ('奈良県','Nara'),
    ('和歌山県','Wakayama'),
    ('鳥取県','Tottori'),
    ('島根県','Shimane'),
    ('岡山県','Okayama'),
    ('広島県','Hiroshima'),
    ('山口県','Yamaguchi'),
    ('徳島県','Tokushima'),
    ('香川県','Kagawa'),
    ('愛媛県','Ehime'),
    ('高知県','Kochi'),
    ('福岡県','Fukuoka'),
    ('佐賀県','Saga'),
    ('長崎県','Nagasaki'),
    ('熊本県','Kumamoto'),
    ('大分県','Oita'),
    ('宮崎県','Miyazaki'),
    ('鹿児島県','Kagoshima'),
    ('沖縄県','Okinawa'),
]







export const GENRES = [
    'Breaking','Popping','Jazz','Contemporary'
]
export const DIFFICULTIES = [
    'First Time', 'Beginner','Intermediate','Advanced','NA'
]



export const SAMPLE_USER = {
    email: 'dummy@example.com',
    password: 'password',
    is_user: true,
    is_instructor: true,

    /**Basic Info */
    firstName: 'John',
    lastName: 'Doe',
    gender: 'Male', 
    birthday: new Date(1993,3,3),

    profilePicture: '/profilepic.jpeg',
    displayName: 'JDoe', 
    area:'Akita',
    interestedGenres: ['Breaking','Popping'],
}


export const SAMPLE_INSTRUCTOR = {
    email: 'instructor@example.com',
    password: 'password',
    is_user: false,
    is_instructor: true,

    /** Basic Info */
    firstName: 'John',
    lastName: 'Doe',
    gender: 'Male', 
    birthday: new Date(1993,3,3),

    /** Instructor Profile */
    instructorName: 'BBOY JD',
    profilePicture: '/profilepic.jpeg',
    area: 'Aomori',
    genres: ['Breaking','Popping'],
    teams: "RBBC1 All Stars",
    achievements: 'RBBC1 2023 Champion',
    selfIntro: 'I am a champion',

    profilePicture: {path: '/profilepic.jpeg'},
    coverPhoto: {path: '/profilepic.jpeg'},

    media: []
}