

export const calcEndTime = (time: string, duration: number|null): string => {
    if (duration==null) {
        return time
    }
    //test next day

    //time: '0:00'
    //timeHr, timeMin : 0 / 0
    const timeHr = time.split(':')[0]
    const timeMin = time.split(':')[1]
    
    //duration: 1200
    //durHr, durMin : 3 / 14
    const durMin = duration % 60
    const durHr = Math.floor(duration / 60)
    
    //initial calculation of end time
    let endTimeMin = Number(timeMin) + durMin
    let endTimeHr = Number(timeHr) + durHr

    //adjust end time if minute is over 60
    if (endTimeMin>=60) {
        const extraHr = Math.floor(endTimeMin / 60)
        endTimeHr += extraHr
        endTimeMin %=60
    }

    //finalize end time into string
    const endTime = `${endTimeHr}:${endTimeMin.toString().padStart(2,'0')}`

    return endTime
}

export const durationToString = (duration: number, l:'en' |'jp'): string => {
    //duration: 1200
    //durHr, durMin : 3 / 14
    const durMin = duration % 60
    const durHr = Math.floor(duration / 60)

    if (l=='jp') {
        return `${durHr}時間${durMin}分`
    } else {
        return `${durHr}hrs ${durMin}min`
    }
}
